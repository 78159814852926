<template>
  <div class="page">
    <Navbar title="咨询服务" type="SMP" />
    <div class="banner">
      <van-image
        :src="require('../../../assets/images/main/consult/consult.jpg')"
      ></van-image>
    </div>
    <div class="operator">
      <div class="title">
        西安市市微职客运营管理中心 <van-tag :color="COLOR_M">联营</van-tag>
      </div>
      <van-image
        class="map"
        :src="require('../../../assets/images/main/consult/op-1-add.png')"
      ></van-image>
      <div class="address">
        <van-icon name="location-o" />地址：西安市雁塔区科技路创业广场C座
      </div>
      <div class="phone">
        <van-icon name="phone-circle-o" />电话：029-85798123
      </div>
      <div class="title">咨询服务专员</div>
      <div class="director">
        <van-collapse v-model="activeName" accordion>
          <van-collapse-item name="1">
            <template #title>
              <div><van-icon name="user-o" /> 赵老师</div>
            </template>
            <van-row class="phone">
              <van-col span="12" class="number"
                ><van-icon name="phone-circle-o" />18189182609</van-col
              >
              <van-col span="12" class="btn"
                ><van-tag :color="COLOR_M" @click="call('18189182609')"
                  ><van-icon name="share-o" /> 立即拨打</van-tag
                ></van-col
              >
            </van-row>
            <van-row>
              <van-col span="12" class="wechat">
                <van-image
                  class="img"
                  :src="require('../../../assets/images/main/consult/bs01.jpg')"
                ></van-image>
              </van-col>
              <van-col span="12" class="tips"> 长按二维码扫码添加微信 </van-col>
            </van-row>
          </van-collapse-item>
        </van-collapse>
      </div>
    </div>
    <Share ref="share" />
  </div>
</template>
<script>
import { Image, Icon, Tag, Collapse, CollapseItem } from 'vant'
import Navbar from '../../module/common/Navbar.vue'
import Share from '../../module/common/Share.vue'
export default {
  components: {
    Navbar: Navbar,
    Share: Share,
    [Image.name]: Image,
    [Icon.name]: Icon,
    [Tag.name]: Tag,
    [Collapse.name]: Collapse,
    [CollapseItem.name]: CollapseItem
  },
  data () {
    return {
      activeName: '1',
      operatorCode: ''
    }
  },
  mounted () {
    var operator = window.sessionStorage.getItem(this.SESSION_OPERATOR)
    this.operatorCode = operator
    this.$refs.share.default('main')
  },
  methods: {
    back () {
      alert(1)
    },
    call (number) {
      window.location.href = 'tel:' + number
    }
  }
}
</script>
<style lang="less" scoped>
</style>
